
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import PrimaryLogo from '@/components/logo/Primary.vue';
import LanguageCallout from '@/components/LanguageCallout.vue';
import SurveyRequestsPromptBanner from '@/components/Survey/RequestsPromptBanner.vue';
import TheLanguageSelect from '@/components/TheLanguageSelect.vue';
import TheAccountNavigation from '@/components/TheAccountNavigation.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import FooterLinks from '@/components/FooterLinks.vue';
import HistoricalWeatherPrompt from '@/components/HistoricalWeatherPrompt/Index.vue';

export default Vue.extend({
    metaInfo: {
        title: 'Community Climate & Weather Journal',
        titleTemplate: 'ISeeChange | %s',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content: 'Investigate how weather and climate change are impacting our communities and environment.',
            },
            {
                property: 'og:title',
                content: 'Community Climate & Weather Journal',
                template: chunk => `ISeeChange - ${chunk}`,
                vmid: 'og:title'
            },
            {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
            },
            {
                property: 'og:site_name',
                content: 'ISeeChange',
                vmid: 'og:site_name'
            },
            {
                property: 'og:description',
                content: 'Investigate how weather and climate change are impacting our communities and environment.',
                vmid: 'og:description'
            },
            {
                property: 'twitter:card',
                content: 'summary',
                vmid: 'twitter:card'
            },
            {
                property: 'twitter:site',
                content: '@iseechange',
                vmid: 'twitter:site'
            },
            {
                property: 'twitter:title',
                content: 'ISeeChange - Community Climate & Weather Journal',
                vmid: 'twitter:title'
            },
            {
                property: 'twitter:description',
                content: 'Investigate how weather and climate change are impacting our communities and environment.',
                vmid: 'twitter:description'
            },
        ],
    },
    components: {
        LanguageCallout,
        TheLanguageSelect,
        PrimaryLogo,
        UserAvatar,
        SurveyRequestsPromptBanner,
        TheAccountNavigation,
        FooterLinks,
        HistoricalWeatherPrompt,
    },
    data() {
        return {
            menuOpen: false,
            userMenu: false,
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
        hideMainNav(): boolean {
            return !this.currentUser && this.$route.matched.some(record => record.meta.hideMainNavWithNoUser);
        },
        hasClientGroup(): boolean {
            return (this.currentUser?.clientGroups?.length ?? 0) !== 0;
        },
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
    },
});
